<template>
  <div id="basic_info">
    <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="用户头像：">
        <div class="personal_info" >
<!--          action="http://api.pxb7.com/api/upload/avatar"-->
          <el-upload
            class="basicInfo-uploader"
            :show-file-list="false"
            action="https://api.pxb7.com/api/upload/avatar"
            :headers="{
              LoginStatus: this.$store.state.loginStatus,
              Authorization: this.$store.state.token,
            }"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="basicInfo">
            <img v-else-if="infoForm.touxiang" :src="infoForm.touxiang" class="basicInfo">
            <i v-else class="el-icon-plus basicInfo-uploader-icon"></i>
            <el-button size="small" type="primary" class="basicInfo-upload-btn">{{ elUploadBtn }}</el-button>
          </el-upload>
        </div>
<!--        <div class="personal_info" v-else>-->
<!--          <img :src="this.$store.state.userData.touxiang" alt="">-->
<!--        </div>-->
      </el-form-item>
      <el-form-item label="手机号：" class="userPhone"  prop="ph_num">
        <div class="personal_info">
          {{this.infoForm.ph_num}}
        </div>
        <router-link tag="div" :to="{name:'ChangeTelphone'}" class="changePhone">
          换绑手机号
        </router-link>
      </el-form-item>
      <el-form-item label="昵称："  prop="account">
        <el-input placeholder="请输入昵称" v-model="infoForm.account" ></el-input>
      </el-form-item>
      <el-form-item label="邮箱："  prop="email">
        <el-input placeholder="请确认邮箱" v-model="infoForm.email" ></el-input>
      </el-form-item>
      <el-form-item label="QQ号："  prop="qq_num">
        <el-input placeholder="请确认QQ号" v-model="infoForm.qq_num" ></el-input>
      </el-form-item>
    </el-form>
    <div class="submit_btn" @click="submitForm('infoForm')">提交信息</div>

  </div>
</template>
<script>
import {apiUserSave,apiUploadPage,apiuserInfo} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      //头像
      imageUrl: '',
      //完整头像地址
      file_path:'',
      //上传按钮文本
      elUploadBtn:'点击上传',
      //表单
      infoForm:{
        account:this.$store.state.userData.nickname,
        ph_num:this.$store.state.userData.telphone,
        email:this.$store.state.userData.email,
        qq_num:this.$store.state.userData.qq,
        touxiang:this.$store.state.userData.touxiang,
      },
      //表单验证
      rules: {
        account:[
          { required: true, message: '请输入昵称', trigger: 'blur' },
        ],
        // ph_num:[
        //   { required: true, message: '请输入用户手机号码', trigger: 'blur' },
        //   { min: 11, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
        // ],
        email: [
          { pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/, message: '邮箱格式错误'}
        ],
        qq_num: [
          { pattern: /^[1-9][0-9]{4,9}$/, message: 'QQ账号格式错误'}
        ],
      }
    }
  },
  methods: {
    //头像上传
    handleAvatarSuccess(res, file,fileList) {
      if(res.code === 200){
        this.imageUrl = URL.createObjectURL(file.raw);
        this.infoForm.touxiang = res.data.src;
        this.file_path = res.data.file_path;
      }else{
        this.$message({
          message:res.msg+" (上传头像图片只能是 JPG/PNG 格式)",
          center:true,
          type:"error"
        })
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiUserSave({
            nickname:this.infoForm.account,
            phone:this.infoForm.ph_num,
            qq:this.infoForm.qq_num,
            email:this.infoForm.email,
            touxiang:this.infoForm.touxiang
          }).then(res=>{
            this.$message({
              message:"提交成功",
              type:"success",
              center:true
            })
            apiuserInfo({
              action:'base'
            }).then(res=>{
              this.$store.commit('user_BasicInfo',res.data)
            })
          }).catch(err=>{
            this.$message({
              message:err.msg,
              type:"error",
              center:true
            })
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    /**
     * 获取基本信息
     */
    getBasicInfo(){
      apiuserInfo({
        action:'base'
      }).then(res=>{
        this.infoForm.ph_num = res.data.telphone
        this.infoForm.email = res.data.email
        this.infoForm.account = res.data.nickname
        this.infoForm.qq_num = res.data.qq
        this.infoForm.touxiang = res.data.touxiang
      })
    },
  },
  mounted () {
    this.getBasicInfo()
  },
  watch: {
    imageUrl(){
      if (this.imageUrl !== '') {
        this.elUploadBtn = '重新上传'
      }
    }
  },
  computed: {
  },
  created() {
    if (localStorage.getItem('loginStatus') == "true") {
      this.infoForm.account = JSON.parse(localStorage.getItem('userData')).nickname
      this.infoForm.ph_num = JSON.parse(localStorage.getItem('userData')).telphone
      this.infoForm.email = JSON.parse(localStorage.getItem('userData')).email
      this.infoForm.qq_num = JSON.parse(localStorage.getItem('userData')).qq
      this.infoForm.touxiang = JSON.parse(localStorage.getItem('userData')).touxiang
    }
  }
}
</script>

<style>
  .basicInfo-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 40px;
    cursor: pointer;
    position: relative;
    /*overflow: hidden;*/
  }
  .basicInfo-upload-btn{
    position: absolute;
    top: 23px;
    left: 110px;
    width: 102px;
    height: 32px;
    background: #283748 !important;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    padding: 0;
    line-height: 32px;
    text-align: center;
  }
  .basicInfo-upload-btn:hover{
    background: #283748 !important;
  }
  .basicInfo-uploader .el-upload:hover {
    border-color: #FCAD13;
  }
  .basicInfo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .basicInfo {
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 40px;
  }
</style>

<style lang='less' scoped>
 #basic_info{
   padding: 60px 0 0 256px;
   display: flex;
   flex-direction: column;
   //align-items: center;
   /deep/.el-input{
     width: 250px;
   }
   /deep/.el-input__inner{
     width: 250px;
     height: 40px;
     background: #F9F9F9;
     border-radius: 4px;
     border: 1px solid #F0F0F0;
     font-size: 16px;
     font-family: PingFangSC-Thin, PingFang SC;
     font-weight: 300;
     color: #8F8F8F;
   }
   /deep/.is-error .el-input__inner{
     border-color: #F56C6C;
   }
   /deep/.el-form-item__label{
     text-align: left;
     font-size: 16px;
     font-family: PingFangSC-Thin, PingFang SC;
     font-weight: 300;
     color: #5B5B5B;
     padding: 0;
   }
   /deep/.el-form-item:first-of-type{
     display: flex;
     flex-direction: row;
     align-items: center;
   }
   /deep/.el-form-item:first-of-type .el-form-item__content{
     margin: 0 !important;
   }
   /deep/.el-form-item .el-form-item__label{
     padding: 0 0 0 12px;
   }
   /deep/.is-required .el-form-item__label{
     padding:0;
   }
   .personal_info{
     font-size: 16px;
     font-family: PingFangSC-Thin, PingFang SC;
     font-weight: 300;
     color: #8F8F8F;
     padding: 0 0 0 20px;
   }
   .userPhone /deep/.el-form-item__content{
     display: flex;
     align-items: center;
     flex-direction: row;
     .personal_info{

     }
     .changePhone{
       width: 100px;
       height: 36px;
       background: #434ED6;
       border-radius: 10px;
       font-size: 14px;
       font-family: PingFangSC-Thin, PingFang SC;
       font-weight: 300;
       color: #FFFFFF;
       line-height: 36px;
       text-align: center;
       margin:  0 0 0 29px;
       cursor: pointer;
     }
   }

   .submit_btn{
     margin: 29px 0 0 82px;
     width: 200px;
     height: 40px;
     line-height: 40px;
     background: #434ED6;
     border-radius: 28px;
     font-size: 18px;
     font-family: PingFangSC-Light, PingFang SC;
     font-weight: 400;
     color: #FFFFFF;
     text-align: center;
     cursor: pointer;
   }
 }
</style>
